import "../styles/main.css";
import "../styles/fonts.css";
import "../styles/bootstrap.css";
import "../styles/coinbase.css";

import type { AppProps } from "next/app";

function MyApp({ Component, pageProps }: AppProps) {
	return <Component {...pageProps} />;
}

export default MyApp;
